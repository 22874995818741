const dark = {
  title: 'dark',

  colors: {
    primary: '#222',
    secondary: '#C62E65',

    background: '#111',
    text: '#f5f5f5',

    webkit: {
      scrollbar: '#7636ff'
    },

    header: {
      menuIcon: '#f5f5f5'
    },

    switches: {
      onColor: '#7636ff'
    },

    drawer: {
      background: '#111',
      drawerHeader: '#7636ff',
      drawerItemHover: '#7636ff',
      drawerItemText: '#f5f5f5'
    },

    settings: {
      form: '#222',
      formTitle: '#f5f5f5',
      formTitleBackground: '#7636ff',
      fieldBorder: '#f5f5f599',
      fieldTitle: '#f5f5f5',
      switchOn: '#7636ff',
      switchOff: '#7636ff',
      switchHandleOn: '#f5f5f5',
      switchHandleOff: '#f5f5f5'
    }
  },

  newPallete: {
    primary: {
      default: "#7636ff",
      light: "#af67ff",
      dark: "#3400ca",
  
      elements: {
        text: "#ffffff"
      }
    },
    
    secondary: {
      default: "#222222",
      light: "#494949",
      dark: "#000000",
  
      elements: {
        text: "#ffffff"
      }
    }
  }
}

export default dark;